import React from 'react';
import { SanitySellPageHtml } from 'graphql-types';
import { HTML } from './HTML';

interface Props {
  fields: SanitySellPageHtml;
}

export const SanityHTML = ({ fields }: Props) => (
  <HTML html={`${fields.html}`} />
);
