import React, { FC } from 'react';
import { SanityArticlePreviewFragment } from 'graphql-types';
import { PDPPageContainer } from 'components/Pdp';

import { Container } from './ArticlePreview.styles';
import { ArticlePreviewItem } from './ArticlePreviewItem';

export const ArticlePreview: FC<{ data: SanityArticlePreviewFragment }> = ({
  data,
}) => (
  <PDPPageContainer>
    <Container>
      {data?.articles?.map(
        (article, index) =>
          article && <ArticlePreviewItem article={article} key={index} />
      )}
    </Container>
  </PDPPageContainer>
);
