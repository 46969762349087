import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { SanitySellPageIconListsBlock, SanityIconOption } from 'graphql-types';
import { IconsList } from './IconsList';
import { IconProps } from './model';
import { ThemeContext } from 'styled-components';

export const SanityIconsList = ({
  fields,
}: {
  fields: SanitySellPageIconListsBlock;
}) => {
  const theme = useContext(ThemeContext);
  return (
    <IconsList
      title={fields.titleText || ''}
      titleColor={fields.titleFontColor?.hex || theme.colors.blueNF1}
      mobileTitleFontSize={fields.mobileTitleFontSize || 48}
      desktopTitleFontSize={fields.desktopTitleFontSize || 28}
      bgColor={fields.backgroundColor?.hex || theme.colors.basic100}
      layout={fields.layout || 'horizontal'}
      icons={fields.iconList?.map(
        (icon: SanityIconOption): IconProps => ({
          name: icon.name,
          text: icon.text,
          color: icon.fontColor?.hex || theme.colors.default,
          iconImage: icon.iconImage,
        })
      )}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageIconListsBlock on SanitySellPageIconListsBlock {
    _key
    _type
    __typename
    name
    titleText
    titleFontColor {
      hex
    }
    desktopTitleFontSize
    mobileTitleFontSize
    backgroundColor {
      hex
    }
    layout
    iconList {
      name
      text
      fontColor {
        hex
      }
      iconImage {
        asset {
          gatsbyImageData(height: 48, placeholder: NONE)
        }
      }
    }
  }
`;
