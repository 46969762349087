import React from 'react';
import { graphql } from 'gatsby';
import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { LadderLandingPageBySlugQuery } from 'graphql-types';
import { renderLandingPageBlock } from './LandingPage.blocks';
import { useLogPageViewed } from 'helpers/Amplitude';
import { useTagStandardPageView } from 'helpers/Tealium';
import { useGTMTagStandardPageView } from 'helpers/GTM';
import { LadderApp } from 'templates/ladder/LadderApp';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';

export const LandingPageTemplate: React.FC<{
  data: LadderLandingPageBySlugQuery;
}> = ({ data }) => {
  const { slug, blocks, seoMetadata } = data.page || {};

  useLogPageViewed(undefined, {
    type: slug?.current === 'learn' ? 'Learn' : undefined,
  });

  useTagStandardPageView('page_view');
  useGTMTagStandardPageView('page_view');

  return (
    <LadderApp>
      {seoMetadata && slug?.current && (
        <SeoMetadata metaData={seoMetadata} slug={`pages/${slug.current}`} />
      )}
      {blocks
        ?.map((block) => block && renderLandingPageBlock(block))
        .map((block, index) => (
          <ImageLoadingWrapper key={index} eager={index < 2} children={block} />
        ))}
    </LadderApp>
  );
};

export default LandingPageTemplate;

export const query = graphql`
  query LadderLandingPageBySlug($slug: String!) {
    page: sanityLadderLandingPage(slug: { current: { eq: $slug } }) {
      name
      slug {
        current
      }
      seoMetadata {
        ...SeoMetadata
      }
      blocks {
        ...SanityEmailSignupBlock
        ...SanityHeroSlider
        ...SanityCollectionSliderBlock
        ...SanitySubmenu
        ...SanityLadderProductSlider
        ...SanitySimpleMediaWithTextBlock
        ...SanityImageBlock
        ...SanitySquareImageSlider
        ...SanitySellPageInfoCardsBlock
        ...SanitySellPageSpacer
        ...SanityBorderContent
        ... on SanitySellPageMediaWithText {
          ...SanitySellPageMediaWithText
        }
        ... on SanitySellPageIconListsBlock {
          ...SanitySellPageIconListsBlock
        }
        ...SanityRichTextWithBackground
        ...SanityArticlePreview
        ...SanityColumns
        ...SanityLandingProductReviews
        ...SanitySellPageHtml
        ...GenericBundleAddToCart
        ...GenericAddToCart
        ...SanitySellPageStickyCta
        ...SanitySellPageStickyHeader
        ...SanitySellPageStickyCtaShowHide
      }
    }
  }
`;
