import React from 'react';
import {
  Container,
  TitleContainer,
  Title,
  IconsListContainer,
  IconContainer,
  Icon,
  Info,
  InfoText,
  IconPosition,
} from './styles';
import { IconsListProps, IconProps } from './model';
import { SellPageContainer } from 'components/Common/Layout';

export const IconsList: React.FC<IconsListProps> = ({
  title,
  titleColor,
  mobileTitleFontSize,
  desktopTitleFontSize,
  bgColor,
  layout,
  icons,
}) => {
  return (
    <Container background={bgColor} className="IconsListContainer">
      <SellPageContainer>
        <TitleContainer>
          <Title
            color={titleColor}
            fontSize={[`${mobileTitleFontSize}px`, `${desktopTitleFontSize}px`]}
            dangerouslySetInnerHTML={{ __html: title || '' }}
            className="Title"
          />
        </TitleContainer>
        <IconsListContainer layout={layout} className="Icons">
          {icons &&
            icons.map((icon: IconProps, index) => (
              <IconContainer key={icon.name} className={`Icon Icon-${index}`}>
                <IconPosition>
                  <div>
                    <Icon
                      image={icon.iconImage?.asset?.gatsbyImageData}
                      alt={''}
                      className={`IconImage IconImage-${index}`}
                    />
                  </div>
                </IconPosition>
                <Info>
                  <InfoText
                    className={`IconText IconText-${index}`}
                    dangerouslySetInnerHTML={{ __html: icon.text || '' }}
                  />
                </Info>
              </IconContainer>
            ))}
        </IconsListContainer>
      </SellPageContainer>
    </Container>
  );
};
