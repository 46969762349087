import styled from 'styled-components';
import { display } from 'styled-utils';

export const TwoColumns = styled.div`
  ${display({
    display: ['block', 'grid', 'grid'],
  })};
  margin: 0 40px;
  max-width: 933px;
  column-gap: 80px;
  grid-template-columns: 1fr 1fr;
`;

export const TwoColumnsRow = styled.div``;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;
