import React from 'react';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import {
  SanityImage,
  SanitySquareImageSliderEntryFragment,
} from 'graphql-types';

import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { SanityRichText } from 'components/Common/SanityRichText';
import { FullSizeModal, Modal } from 'components/Modal';

import {
  PersonBehind,
  PersonBehindContent,
  PersonFront,
  PersonItem,
  PersonName,
  PersonRole,
  PersonRoleContent,
  PhotoWrapper,
  Photo,
  ModalWrapper,
  PersonModalBody,
  Content,
} from './AboutPersonItem.styles';

export const AboutPersonItem: React.FC<{
  data: SanitySquareImageSliderEntryFragment;
}> = ({ data }) => {
  const { image } = data;
  const [modal, setModal] = React.useState(false);

  const closeModal = () => {
    setModal(false);
  };
  const showModal = () => setModal(true);

  const PersonInfo = () => (
    <PersonRoleContent>
      <PersonName>{data.name}</PersonName>
      <PersonRole>
        <SanityRichText blocks={data._rawCaption} />
      </PersonRole>
    </PersonRoleContent>
  );

  const gatsbyImage = getSimplifiedImageData(image as SanityImage);

  return (
    <>
      <PersonItem onClick={showModal}>
        <PersonFront>
          <PhotoWrapper>
            <Photo>
              {gatsbyImage ? (
                <GatsbyImage image={gatsbyImage} alt={data.name || ''} />
              ) : null}
            </Photo>
          </PhotoWrapper>
          <PersonInfo />
        </PersonFront>
        <PersonBehind>
          <PersonBehindContent>
            <SanityRichText blocks={data._rawDescription} />
          </PersonBehindContent>
          <PersonInfo />
        </PersonBehind>
      </PersonItem>
      {modal && (
        <ModalWrapper>
          <FullSizeModal>
            <Modal
              onOverlayClick={closeModal}
              onBtnCloseClick={closeModal}
              showBtnClose
            >
              <PersonModalBody>
                <Content>
                  <SanityRichText blocks={data._rawDescription} />
                </Content>
                <PersonInfo />
              </PersonModalBody>
            </Modal>
          </FullSizeModal>
        </ModalWrapper>
      )}
    </>
  );
};
