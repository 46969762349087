import { GatsbyImage } from 'components/Common/GatsbyImage';
import styled from 'styled-components';
import { height, grid, space } from 'styled-utils';
import { LadderThemeProp } from 'theme';
import { heading4Style } from 'components/Common/Text';

export const Container = styled.div`
  display: grid;
  ${grid({ gridTemplateColumns: ['1fr', '1fr 1fr'] })};
  grid-gap: 20px;
  ${space({ mx: [20, 0], my: 16 })};
`;

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ArticleImage = styled(GatsbyImage)`
  width: 100%;
  height: 360px;
  margin-bottom: 16px;
  ${height({ height: [200, '30vw', 360] })};
`;

export const ArticleTitle = styled.h4`
  margin-bottom: 16px;
  ${heading4Style};
  color: ${({ theme }) => theme.colors.default};
`;

export const ArticleText = styled.div`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.default};
`;

export const ArticleLink = styled.a`
  margin-bottom: 16px;
  text-decoration: none;
  color: ${({ theme }: LadderThemeProp) => theme.colors.blue700};
`;
