import React from 'react';
import {
  GenericBundleAddToCartFragment,
  SanityOpenfitBundle,
} from 'graphql-types';

import {
  BundleDisplayMethod,
  BundleProductProvider,
} from 'Context/BundleProduct/BundleProduct.context';
import { DEFAULT_BUY_BUTTON_ACTION } from 'Constants';
import { BundleAddToCartBlock } from './BundleAddToCartBlock';

export const GenericBundleAddToCart: React.FC<{
  data: GenericBundleAddToCartFragment;
}> = ({ data: { displayMethod, bundle, oneTimeBundle, addToCart } }) => {
  if (!addToCart || !bundle) {
    return null;
  }

  const { images, couponCode } = bundle;

  return (
    <BundleProductProvider
      displayMethod={displayMethod as BundleDisplayMethod}
      bundle={bundle as SanityOpenfitBundle}
      oneTimeBundle={oneTimeBundle as SanityOpenfitBundle | undefined}
    >
      <BundleAddToCartBlock
        title={addToCart.name}
        images={images}
        couponCode={couponCode}
        description={addToCart.description}
        addToCartAction={
          (addToCart.buyButtonOption as 'checkout' | 'cart') ||
          DEFAULT_BUY_BUTTON_ACTION
        }
      />
    </BundleProductProvider>
  );
};
