import React from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';

import {
  LadderLandingPageBySlugQuery,
  SanityCollectionSliderBlockFragment,
  SanityEmailSignupBlockFragment,
  SanityHeroSlider,
  SanitySimpleMediaWithTextBlockFragment,
  SanitySellPageMediaWithText,
  SanityImageBlock,
  SanitySquareImageSliderFragment,
  SanitySellPageSpacer,
  SanityBorderContentFragment,
  SanitySellPageIconListsBlock,
  SanityArticlePreviewFragment,
  SanityColumnsFragment,
  SanitySellPageInfoCardsBlockFragment,
  SanityLadderProductSliderFragment,
  SanitySubmenuFragment,
  SanityRichTextWithBackgroundFragment,
  SanitySellPageHtml,
  GenericBundleAddToCartFragment,
  SanityGenericAddToCart,
  SanityLandingProductReviewsFragment,
  ProductReviewsBlockFragment,
  SanitySellPageStickyCtaShowHide,
  SanitySellPageStickyCta,
  SanitySellPageStickyHeader,
} from 'graphql-types';

import { SanityEmailSignUp } from 'components/EmailSignUp/SanityEmailSignUp';
import { SimpleMediaWithText } from 'components/SimpleMediaWithText';
import { SanityMediaWithText } from 'components/MediaWithText/SanityMediaWithText';
import { PDPPageContainer } from 'components/Pdp';
import { SubmenuSanity } from 'components/Common/Submenu/Submenu.sanity';
import { SanityHeroCarousel } from 'components/HeroCarousel/SanityHeroCarousel';
import { SanityInfoCards } from 'components/Common/InfoCards/SanityInfoCards';
import { ProductSliderSanity } from 'components/Product/Slider/ProductSlider.sanity';
import { ImageBlockSanity } from 'components/ImageBlock/ImageBlock.sanity';
import { Spacer } from 'components/Spacer/Spacer';
import { SanityCollectionSlider } from 'components/CollectionSlider/SanityCollectionSlider';
import { AboutPerson } from 'components/AboutPerson/AboutPerson';
import { BorderContent } from 'components/BorderContent/BorderContent';
import { RichTextWithBackground } from 'components/RichTextWithBackground/RichTextWithBackground';
import { SanityIconsList } from 'components/Common/IconsList/SanityIconsList';
import { ArticlePreview } from 'components/ArticlePreview/ArticlePreview';
import { Columns } from 'components/Columns/Columns';
import { SanityHTML } from 'components/Html/SanityHTML';
import { GenericBundleAddToCart } from 'components/Product/BundleAddToCartBlock/GenericBundleAddToCart';
import { GenericAddToCart } from 'components/Product/AddToCartBlock/GenericAddToCart';
import { SanityReviewSection } from 'components/Product/ReviewSection';
import { SanityStickyCta } from 'components/StickyCta/SanityStickyCta';
import { StickyCtaShowHide } from 'components/StickyCta/StickyCtaShowHide';
import { SanityStickyHeader } from 'components/StickyHeader/SanityStickyHeader';

type BlocksArrayType = NonNullable<
  LadderLandingPageBySlugQuery['page']
>['blocks'];

export const renderLandingPageBlock = (block: BlockType<BlocksArrayType>) =>
  renderBlockWithId(block, getBlock(block));

function getBlock(block: BlockType<BlocksArrayType>) {
  const type = block.__typename;
  const id = block._key;

  switch (type) {
    case 'SanityEmailSignupBlock':
      return (
        <SanityEmailSignUp
          key={id}
          fields={block as SanityEmailSignupBlockFragment}
        />
      );
    case 'SanityHeroSlider':
      return <SanityHeroCarousel key={id} fields={block as SanityHeroSlider} />;
    case 'SanityCollectionSliderBlock':
      return (
        <SanityCollectionSlider
          key={id}
          {...(block as SanityCollectionSliderBlockFragment)}
        />
      );
    case 'SanitySubmenu':
      return <SubmenuSanity key={id} fields={block as SanitySubmenuFragment} />;
    case 'SanitySimpleMediaWithTextBlock':
      return (
        <PDPPageContainer>
          <SimpleMediaWithText
            fields={block as SanitySimpleMediaWithTextBlockFragment}
          />
        </PDPPageContainer>
      );
    case 'SanitySellPageMediaWithText':
      return (
        <SanityMediaWithText
          key={id}
          fields={block as SanitySellPageMediaWithText}
        />
      );
    case 'SanitySellPageInfoCardsBlock':
      return (
        <SanityInfoCards
          key={id}
          fields={block as SanitySellPageInfoCardsBlockFragment}
        />
      );
    case 'SanityLadderProductSlider':
      return (
        <ProductSliderSanity
          key={id}
          fields={block as SanityLadderProductSliderFragment}
        />
      );
    case 'SanityImageBlock':
      return <ImageBlockSanity key={id} fields={block as SanityImageBlock} />;
    case 'SanitySquareImageSlider':
      return (
        <AboutPerson key={id} data={block as SanitySquareImageSliderFragment} />
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanityBorderContent':
      return <BorderContent data={block as SanityBorderContentFragment} />;
    case 'SanityRichTextWithBackground':
      return (
        <RichTextWithBackground
          data={block as SanityRichTextWithBackgroundFragment}
        />
      );
    case 'SanitySellPageIconListsBlock':
      return (
        <SanityIconsList
          key={id}
          fields={block as SanitySellPageIconListsBlock}
        />
      );
    case 'SanityArticlePreview':
      return (
        <ArticlePreview key={id} data={block as SanityArticlePreviewFragment} />
      );
    case 'SanityColumns':
      return <Columns data={block as SanityColumnsFragment} />;
    case 'SanityLandingProductReviews':
      const {
        dynamicProductReviews,
        manualProductReviews,
      } = block as SanityLandingProductReviewsFragment;
      return (
        <SanityReviewSection
          review={dynamicProductReviews?.review}
          reviewId={dynamicProductReviews?.reviewId}
          staticReviewData={dynamicProductReviews?.staticReviewData}
          product={{
            title: dynamicProductReviews?.name,
            description: dynamicProductReviews?.description,
          }}
          key={id}
          fields={manualProductReviews as ProductReviewsBlockFragment}
        />
      );
    case 'SanitySellPageHtml':
      return <SanityHTML fields={block as SanitySellPageHtml} key={id} />;
    case 'SanityGenericBundleAddToCart':
      return (
        <GenericBundleAddToCart
          data={block as GenericBundleAddToCartFragment}
        />
      );
    case 'SanityGenericAddToCart':
      return (
        <PDPPageContainer>
          <GenericAddToCart key={id} data={block as SanityGenericAddToCart} />
        </PDPPageContainer>
      );
    case 'SanitySellPageStickyCta':
      return (
        <SanityStickyCta key={id} fields={block as SanitySellPageStickyCta} />
      );
    case 'SanitySellPageStickyCtaShowHide':
      return (
        <StickyCtaShowHide
          key={id}
          enableHide={(block as SanitySellPageStickyCtaShowHide)?.enableHide}
        />
      );
    case 'SanitySellPageStickyHeader':
      return (
        <SanityStickyHeader
          key={id}
          fields={block as SanitySellPageStickyHeader}
        />
      );
    default:
      return `Unrecognized block type: ${type}`;
  }
}
