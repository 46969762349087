import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { logEvent, awaitMouseClick } from 'helpers/Amplitude';
import { standardTagMixin } from 'components/Common/Text';

interface Props {
  html: string;
}

export const StyledHTMLContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  ${standardTagMixin}
`;

export const HTML = ({ html }: Props) => {
  const elem = useRef<HTMLDivElement>(null);

  function onClick(event: MouseEvent) {
    return awaitMouseClick((e) =>
      logEvent('Sellpage: Click', {
        key: (e.target as HTMLAnchorElement).href,
      })
    )(event);
  }

  useEffect(() => {
    if (elem.current) {
      const links = elem.current.getElementsByTagName('a');
      for (const link of links) {
        link.addEventListener('click', onClick);
      }
    }

    return () => {
      if (!elem.current) return;
      const links = elem.current.getElementsByTagName('a');
      for (const link of links) {
        link.removeEventListener('click', onClick);
      }
    };
  }, [elem]);

  return (
    <StyledHTMLContainer
      ref={elem}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default HTML;
