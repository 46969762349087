import React from 'react';
import { SanityColumnsFragment, StandardRichTextFragment } from 'graphql-types';
import { StandardRichText } from 'components/StandardRichText/StandardRichText';
import { serializerTypes } from 'components/RichTextWithBackground/RichTextWithBackground';
import { Center, TwoColumns } from './Columns.styles';

export const Columns: React.FC<{ data: SanityColumnsFragment }> = ({
  data,
}) => (
  <Center>
    <TwoColumns>
      <StandardRichText
        fields={data.first as StandardRichTextFragment}
        serializerTypes={serializerTypes}
      />
      <StandardRichText
        fields={data.second as StandardRichTextFragment}
        serializerTypes={serializerTypes}
      />
    </TwoColumns>
  </Center>
);
