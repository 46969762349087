import React, { FC } from 'react';
import { SanityRichText } from 'components/Common/SanityRichText';
import {
  SanitySquareImageSliderEntryFragment,
  SanitySquareImageSliderFragment,
} from 'graphql-types';
import { AboutPersonItem } from './AboutPersonItem';
import {
  AboutPersonList,
  Title,
  Subtitle,
  PersonListContent,
  PersonList,
  PersonListWrapper,
} from './AboutPerson.styles';

export const AboutPerson: FC<{ data: SanitySquareImageSliderFragment }> = ({
  data,
}) => (
  <AboutPersonList>
    {data.title && <Title>{data.title}</Title>}
    {data._rawSubtitle ? (
      <Subtitle>
        <SanityRichText blocks={data._rawSubtitle} />
      </Subtitle>
    ) : null}
    <PersonListWrapper>
      <PersonListContent>
        <PersonList>
          {(data.images || []).map(
            (item: SanitySquareImageSliderEntryFragment) => {
              return <AboutPersonItem key={item.image?._key} data={item} />;
            }
          )}
        </PersonList>
      </PersonListContent>
    </PersonListWrapper>
  </AboutPersonList>
);
