import styled from 'styled-components';
import { space } from 'styled-utils';

export const PersonListWrapper = styled.span`
  width: 100%;
`;

export const PersonListContent = styled.div`
  padding: 0 15px;
  ${space({ mx: [0, 0, 100, 'auto'] })};
  max-width: 1400px;
`;

export const PersonList = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const AboutPersonList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fafafa;
  padding: 4.5rem 0 3.3rem;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.alternate};
  font-size: 28px;
  line-height: 1.3;
  text-transform: uppercase;
  ${space({ mb: ['2rem'] })};
`;

export const Subtitle = styled.p`
  max-width: 400px;
  ${space({ mb: ['3.8rem'] })};
`;
