import React from 'react';
import { useLocation } from '@reach/router';

import { SanityGenericAddToCart } from 'graphql-types';

import { OpenfitProduct } from 'Context/Product/Product.context.model';
import { ProductProvider } from 'Context/Product/Product.context';
import { getProductContextData } from 'Context/Product/Product.helper';
import { DEFAULT_BUY_BUTTON_ACTION } from 'Constants';

import { AddToCartBlock } from 'components/Product/AddToCartBlock/AddToCartBlock';

export const GenericAddToCart: React.FC<{ data: SanityGenericAddToCart }> = ({
  data,
}) => {
  const { search } = useLocation();
  const product: OpenfitProduct = data.product as OpenfitProduct;

  const contextData = getProductContextData(
    product,
    new URLSearchParams(search).get('sku') || ''
  );

  return (
    <ProductProvider {...contextData} product={product}>
      <AddToCartBlock
        addToCartAction={
          (data?.addToCart?.buyButtonOption as 'checkout' | 'cart') ||
          DEFAULT_BUY_BUTTON_ACTION
        }
      />
    </ProductProvider>
  );
};
