import React, { FC } from 'react';
import { SanityLadderArticlePage } from 'graphql-types';
import {
  ItemContainer,
  ArticleImage,
  ArticleTitle,
  ArticleText,
  ArticleLink,
} from './ArticlePreview.styles';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

export const ArticlePreviewItem: FC<{
  article: Partial<SanityLadderArticlePage>;
}> = ({ article }) => {
  const articleImage = getSimplifiedImageData(article.image?.image);
  return (
    <ItemContainer>
      <ArticleLink
        href={`/pages/${article.slug?.current}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {articleImage ? (
          <ArticleImage
            alt={article.image?.alt || ''}
            image={articleImage}
            imgStyle={{ objectFit: 'cover' }}
          />
        ) : null}
        <ArticleTitle>{article.name}</ArticleTitle>
        <ArticleText>{article.previewText}</ArticleText>
        Read more &gt;
      </ArticleLink>
    </ItemContainer>
  );
};
